/**
 *
 * Form - A library of components to build a form
 * @author Chad Watson
 *
 */

import DmpIcon from "common/components/DmpIcon";
import { CheckmarkIcon, CloseCancelIcon } from "components/Icons";
import LoadingSpinner from "components/LoadingSpinner";
import NakedButton from "components/NakedButton";
import { BORDER_RADIUS } from "constants/index";
import { ESCAPE } from "containers/App/constants";
import {
  themeGray,
  themeGrayAccent,
  themeGrayMedium,
  themeGrayXlight,
  themePrimary,
  themeSecondary,
  themeSuccess,
  themeTextColor,
} from "containers/Theme";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled, { css, useTheme } from "styled-components/macro";
import { seconds } from "utils/dates";
import { media } from "utils/styles";
import { CloseButton } from "./CloseButton";
import FormWrapper from "./FormWrapper";
import messages from "./messages";

export { default as AddButton } from "./AddButton";
export { default as AllRowsToggles } from "./AllRowsToggles";
export { default as BigField } from "./BigField";
export { default as BigPasswordField } from "./BigPasswordField";
export { default as ClearFormButton } from "./ClearFormButton";
export { CloseButton, CloseButtonWithIcon } from "./CloseButton";
export { default as DateField } from "./DateField";
export { default as DeleteButton } from "./DeleteButton";
export { default as Errors } from "./Errors";
export { default as Field } from "./Field";
export { default as FieldValidationError } from "./FieldValidationError";
export { default as FieldWithLabel } from "./FieldWithLabel";
export { default as FieldWithSuggestions } from "./FieldWithSuggestions";
export { default as FieldWithValidation } from "./FieldWithValidation";
export { default as Help } from "./Help";
export { default as HelpTitle } from "./HelpTitle";
export { default as Label } from "./Label";
export { default as NameField } from "./NameField";
export { default as NumberField } from "./NumberField";
export { default as ResetButton } from "./ResetButton";
export { default as SaveButton } from "./SaveButton";
export { default as StandaloneFormControlBar } from "./StandaloneFormControlBar";
export { default as TimeField } from "./TimeField";
export { default as withErrorMessage } from "./withErrorMessage";

export const STATUSES = {
  LOADING: "LOADING",
  SAVING: "SAVING",
  SAVED: "SAVED",
  DELETING: "DELETING",
  DELETED: "DELETED",
  ERROR: "ERROR",
  IDLE: "IDLE",
};

export const PROCESSING_STATUSES = [
  STATUSES.LOADING,
  STATUSES.SAVING,
  STATUSES.DELETING,
];

export const STATUS_MESSAGES = {
  [STATUSES.LOADING]: messages.loading,
  [STATUSES.SAVING]: messages.saving,
  [STATUSES.SAVED]: messages.saved,
  [STATUSES.DELETING]: messages.deleting,
  [STATUSES.DELETED]: messages.deleted,
  [STATUSES.ERROR]: messages.error,
  [STATUSES.IDLE]: messages.idle,
};

const sharedCellStyles = css`
  &:first-child {
    width: 100%;
    padding-left: 5px;
    text-align: left;
  }
`;
const sharedTitleStyles = css`
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;

  ${media.small`
    font-size: 24px;
  `};
`;

export const FormHeader = styled.header`
  margin: 5px 0 30px;
`;

export const FormTitle = styled.h3`
  display: flex;
  align-items: center;
  margin: 0 0 5px;
  color: ${themeGray};
  font-size: 28px;
  font-weight: bold;
  line-height: 1.25;

  ${media.small`
    font-size: 36px;
  `};
`;

export const FormTitleIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3em;
  line-height: inherit;
`;

export const FormDescription = styled.p`
  margin: 10px 0 0;
  font-size: 400;
  color: ${themeGrayMedium};
`;

export const Section = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 10px;
  padding: 0 0 5px 5px;
  border-bottom: 2px solid ${themeGrayAccent};
`;

export const SectionTitle = styled.h4`
  ${sharedTitleStyles};
  padding: 0;
  margin: 0;
  line-height: 1;
`;

export const SectionContent = styled.div`
  position: relative;
`;

export const Fields = styled.div`
  width: 100%;

  & > * {
    width: 100%;
    margin-bottom: 5px;
  }

  ${media.small`
    display: flex;
    align-items: flex-end;

    & > :not(:first-child) {
      margin-left: 5px;
    }
    & > :not(:last-child) {
      margin-right: 5px;
    }
  `};
`;
export const TableRow = styled.tr`
  &:nth-child(even) {
    background: ${themeGrayXlight};
  }

  &:first-child td {
    padding-top: 8px;
  }
`;
export const HeaderCell = styled.th`
  ${sharedCellStyles};
  padding-bottom: 5px;
  vertical-align: bottom;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  white-space: pre;

  &:first-child {
    ${sharedTitleStyles};
    display: block;
    padding-right: 0;

    ${media.small`
      display: table-cell;
    `};
  }
`;
export const TableCell = styled.td`
  ${sharedCellStyles};
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: middle;
`;
export const HeaderButton = styled(NakedButton)`
  &:hover {
    color: ${themePrimary};
  }
`;
export const Footnote = styled.p`
  position: relative;
  padding-left: 0.75em;
  margin: 0.8em 0;
  color: ${themeTextColor};
  font-size: 12px;
  line-height: 1.25;
`;
export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const ContentWrapper = styled.div`
  padding: 15px;

  ${media.small`
    padding: 10px 30px 35px;
  `};
`;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px 5px;
  height: 55px;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  background: white;

  & button {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ActionsRight = styled(ActionsContainer)`
  justify-content: flex-end;
`;
export const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${themeSecondary};
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
`;
export const LoadingMessageIconWrapper = styled.div`
  margin-right: 5px;
  font-size: 1.1em;
  line-height: 1;
`;
export const SavedIcon = styled(CheckmarkIcon)`
  color: ${themeSuccess};
`;
export const SecondaryText = styled.span`
  color: ${themeGrayMedium};
  font-size: 0.875rem;
  font-weight: 500;
`;

function Form({
  actionButtons,
  children,
  status,
  onClose,
  onClearSavedStatus,
  className,
  disabled,
}) {
  const hasActionRow = !!onClose || !!actionButtons.length;

  React.useEffect(() => {
    if (onClearSavedStatus && status === STATUSES.SAVED) {
      const timeout = setTimeout(onClearSavedStatus, seconds(5));
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status]); // eslint-disable-line

  React.useEffect(() => {
    if (onClose) {
      const handleKeyDown = ({ which }) => {
        if (which === ESCAPE) {
          onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [onClose]);

  const theme = useTheme();

  const statusMessage = STATUS_MESSAGES[status];

  return (
    <FormWrapper
      hasActionRow={hasActionRow}
      className={className}
      disabled={disabled}
    >
      {hasActionRow && (
        <Actions>
          <ActionsContainer>
            {!!onClose && (
              <CloseButton onClick={onClose} type="button">
                <CloseCancelIcon />
              </CloseButton>
            )}
            {Boolean(statusMessage) && status !== STATUSES.IDLE ? (
              <LoadingMessage>
                <LoadingMessageIconWrapper>
                  {status === STATUSES.SAVED ? (
                    <DmpIcon icon="checkmark" color={theme.success} />
                  ) : status === STATUSES.ERROR ? (
                    <DmpIcon icon="radial_alert" color={theme.warning} />
                  ) : (
                    <LoadingSpinner />
                  )}
                </LoadingMessageIconWrapper>
                <FormattedMessage {...statusMessage} />
              </LoadingMessage>
            ) : null}
          </ActionsContainer>
          <ActionsRight>{actionButtons}</ActionsRight>
        </Actions>
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </FormWrapper>
  );
}

Form.propTypes = {
  children: PropTypes.node,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  status: PropTypes.oneOf(Object.keys(STATUSES).map((key) => STATUSES[key])),
  onClose: PropTypes.func,
  onClearSavedStatus: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  actionButtons: [],
};

export default Form;
