/**
 *
 * ErrorBoundary
 * @author Chad Watson
 *
 *
 */
import { ButtonWithIcon } from "components/Button";
import CycleIcon from "components/Icons/CycleIcon";
import VirtualKeypadLogo from "components/VirtualKeypadLogo";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled, { ThemeProvider } from "styled-components/macro";
import messages from "./messages";
const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px 30px 60px;
`;
const AppLogo = styled(VirtualKeypadLogo)`
  max-width: 320px;
  margin-bottom: 10px;
`;
const MainText = styled.h1`
  font-size: 1.5em;
  font-weight: 300;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  state = {
    hasError: false,
    eventId: null,
  };

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    }

    // Sentry.withScope((scope) => {
    //   scope.setExtras(info);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({
    //     eventId,
    //   });
    // });
  }

  refresh = () => {
    window.location.reload(true);
  };

  render() {
    return this.state.hasError ? (
      <Root className={this.props.className}>
        <AppLogo type="dark" />
        <MainText>
          <FormattedMessage {...messages.mainMessage} />
        </MainText>
        <ThemeProvider
          theme={(currentTheme) => ({
            ...currentTheme,
            color: currentTheme.secondary,
            opposedColor: currentTheme.trueWhite,
          })}
        >
          <Buttons>
            <ButtonWrapper>
              <ButtonWithIcon icon={<CycleIcon />} onClick={this.refresh}>
                <FormattedMessage {...messages.refreshButtonText} />
              </ButtonWithIcon>
            </ButtonWrapper>
          </Buttons>
        </ThemeProvider>
      </Root>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
