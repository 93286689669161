import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const NakedButtonLink = styled(Link)`
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-shadow: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  font-weight: inherit;
  line-height: 1;

  &:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    cursor: default;
  }
`;

export default NakedButtonLink;
